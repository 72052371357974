import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


Vue.use(VueRouter)

const routes = [ 
  { 
    path: '/', 
    redirect:'/home',
    component: () => import( '../views/Homepage.vue'),
    children: [
      {path: '/home',
      component: () => import( '../views/Home.vue'),
      meta:{
        title:'觅明科技'
      }
    },
      { path: '/introduce/:id', 
      component: () => import( '../views/Introduce.vue'),
      meta:{
        title:'公司介绍'
      }
    },
      { path: '/dynamic/:id',
       component: () => import( '../views/Dynamic.vue'),
        meta:{
          title:'动态新闻'
        }
      },
      { path: '/product/:id', 
      component: () => import( '../views/Product.vue'),
      meta:{
        title:'产品介绍'
      }
     },
      { path: '/solve/:id',
       component: () => import( '../views/Solve.vue'),
       meta:{
        title:'解决方案'
      }
      },
      { path: '/details',
      component: () => import( '../views/Details.vue'),
        meta:{
          title:'相关介绍'
        }
      },
      { path: '/Online_tools',
      component: () => import( '../views/Online_tools.vue'),
        meta:{
          title:'在线工具'
        }
    },
      { path: '/cardinfo',component: () => import( '../views/cardinfo.vue')},
      { path: '/aliyunmqtttls',component: () => import( '../views/aliyunmqtttls.vue')},
      { path: '/aliyunmqtt',component: () => import( '../views/aliyunmqtt.vue')},
      { path: '/nnhpcloudmqtt',component: () => import( '../views/nnhpcloudmqtt.vue')},
      { path: '/contactus',
      component: () => import( '../views/contactus.vue'),
      meta:{
        title:'联系我们'
      }
    },
    ]
  },
  //{ path: '/home', component: Home },
  { path: '/login',component: () => import( '../views/Login.vue')},
  { path: '/register',component: () => import( '../views/Register.vue')},
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//前置守卫
router.beforeEach((to, from, next) => {
  NProgress.start();
  // document.title = to.name;
  // if (to.path == '/login' || to.path == '/register')
  // {
  //   next();
  // }
  // else
  // {
  //   const token = localStorage.getItem('token');
  //   if(token)
  //   {
  //     next();
  //   }
  //   else
  //   {
  //     alert('您还没有登录，请先登录');
  //     next('/login');
  //   }
    
  // }
  next();
  // //判断其他页面是否有token
  // const token = localStorage.getItem('token');

  // //存在继续往后走
  // if (token) return next();
  // router.push('/')
})


//后置守卫
router.afterEach((to, from) => {
  // ...
  NProgress.done();
})
export default router
